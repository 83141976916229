

html, body {
  max-width:100%;
  height: auto;
  min-height: 100vh;
}

#root {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
}

body {
  display: flex;
  letter-spacing: -0.5px;
  text-align: left;
  background: #000;
  text-rendering: auto;
  -webkit-font-smoothing: auto;
}

body, h1, h2, h3, h4, button {
  font-family: "Poppins", sans-serif;
  margin: 0;
}


:focus-visible {
  outline: none
}

p {
  line-height: 22px;
  margin: 0
}

a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

svg {
  overflow: visible !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none
}

button {
  padding: 0;
  background: transparent;
  color: inherit;
}
