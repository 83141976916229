
.splash-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background: #000;
}